<!-- 报告2.3 -->
<template>
    <div class="newReport">
        <div class="header flex-align-between">
            <span>我的报告</span>
            <div class="type flex-layout">
                <div class="item flex-align-around"
                    :class="{ 'active-item': typeIndex == index && $route.query.showType != 1 }"
                    v-for="(item, index) in types_role == 3 ? principal_typeList : tea_typeList" :key="index"
                    @click="changeType(index)">{{
                        item }}</div>
            </div>
            <div class="back flex-align" @click="goBack">
                <img src="../../assets/newReport/back.png" alt="">
                返回
            </div>
        </div>
        <!-- 教学应用报告 -->
        <!-- 校长应用报告 -->
        <template v-if="types_role == 3">
            <template v-if="$route.query.showType != 1 ? typeIndex == 0 : typeIndex == '3'">
                <applactionReportVue></applactionReportVue>
            </template>
            <!-- 前后测报告 -->
            <template v-if="$route.query.showType != 1 ? typeIndex == 1 : typeIndex == 0">
                <frontBackaAppraisal :reportVersion="reportVersion"></frontBackaAppraisal>
            </template>
            <!-- 报告助手 -->
            <template v-if="$route.query.showType != 1 ? typeIndex == 2 : typeIndex == 1">
                <Report_whole></Report_whole>
            </template>
        </template>
        <!-- 教师应用报告 -->
        <template v-if="types_role == 2">
            <template v-if="typeIndex == 0">
                <frontBackaAppraisal_tea></frontBackaAppraisal_tea>
            </template>
            <template v-if="typeIndex == 1">
                <Report_whole></Report_whole>
                <!-- <div class="teac_empty">
                    <el-empty description="暂无报告"></el-empty>
                </div> -->
            </template>
        </template>
    </div>
</template>
<script>
import applactionReportVue from './applicationReport.vue'
import frontBackaAppraisal from './frontBackaAppraisal.vue';
import Report_whole from '../report/report_whole.vue';
import frontBackaAppraisal_tea from './frontBackaAppraisal_tea.vue'
export default {
    components: {
        applactionReportVue,
        frontBackaAppraisal,
        Report_whole,
        frontBackaAppraisal_tea
    },
    data() {
        return {
            principal_typeList: [],
            tea_typeList: [],
            // ,'月测/活动报告'
            typeIndex: 0,
            orgId: null,
            termKey: null,
            termList: [],
            types_role: '',
            reportVersion: null
        }
    },
    methods: {
        changeType(index) {
            this.typeIndex = index
        },
        goBack() {
            window.close();
            // this.$router.go(-1)
        },
        // 获取orgId
        getOrgId() {
            this.$Api.Home.queryUserMaxOrg()
                .then(res => {
                    this.orgId = res.data.orgId
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    this.termList = res.data;
                    this.termKey = this.termList[0].key;
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        async getrole() {
            //判断从哪来
            let resData = await this.$Api.Home.queryUserMaxOrg();
            //大屏进入
            if (this.$route.query.fromType == 'multiRole') {
                if (this.$route.query.type == 'school') {
                    this.typeIndex = 0;
                    this.types_role = 3;
                } else {
                    this.typeIndex = 0;
                    this.types_role = 2;
                }
            } else if (this.$route.query.fromType == 'home') {
                //首页进入
                let roles = [];
                resData.data.roles.map(item => {
                    roles.push(item.id)
                })
                for (let i = 0; i < roles.length; i++) {
                    if (roles[i] === 2) {
                        this.types_role = 2;
                    } else if (roles[i] === 3) {
                        this.types_role = 3;
                    } else if (roles[i] === 2 && roles[i] === 3) {
                        this.types_role = 3;
                    }
                }
            }
        },
        // 判断新旧报告
        async getReportVersion() {
            let data = {
                termKey: this.whole_termValue,
                schoolId: this.$route.query.schoolId,
            }
            let resData = await this.$Api.DataScreen.getIsNewDIm(data);
            // console.log('resData-----------',resData);
            if (resData.data == 1) {
                this.reportVersion = 'old'
            } else {
                this.reportVersion = 'new'
            }
        }
    },
    mounted() {
        // this.getOrgId();
        // this.getTermList();
        this.getrole();
        // this.getReportVersion()
        if (this.$route.query.showType == 1) {
            this.principal_typeList = ['测评报告', '报告助手'];
            this.tea_typeList = ['测评报告', '报告助手'];
        } else {
            this.principal_typeList = ['教学应用报告', '测评报告', '报告助手'];
            this.tea_typeList = ['测评报告', '报告助手'];
        }
    },
};
</script>
<style lang='less' scoped>
@import url("./less/newReport.less");
</style>