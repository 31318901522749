<template>
    <div class="whole">
        <div class="export flex-align">
            <div class="wrap generate flex-align" v-if="downloadBtnType">
                <i class="el-icon-download" @click="getgenerate" v-if="reportStatus == 0"> 生成个人报告</i>
                <span v-if="reportStatus == 1"> 报告生成中 <i class="el-icon-loading"></i></span>
                <span v-if="reportStatus == 2"><i class="el-icon-success"
                        style="color:#01b78f;margin-right: 5px;"></i>报告已生成</span>
            </div>
            <el-select v-model="whole_termValue" :popper-append-to-body="false" placeholder="请选择" @change="termChange">
                <el-option v-for="(item, index) in whole_termList" :key="index" :label="item.termName" :value="item.key">
                </el-option>
            </el-select>
            <el-select v-model="reportValue" :popper-append-to-body="false" placeholder="请选择" @change="reportChange">
                <el-option v-for="item in reportList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-select v-model="monthValue" :popper-append-to-body="false" placeholder="请选择" @change="monthChange"
                v-if="reportValue == '4'">
                <el-option v-for="item in monthList" :key="item.create_at" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="weekValue" placeholder="请选择" @change="weekValTypeChange" v-if="reportValue == '5'">
                <el-option v-for="(item, index) in weekList" :key="index" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <!-- downloadBtnType &&  -->
            <div class="wrap flex-align" @click="getbatchdownload('btn')" v-if="table_type == 2">
                <img src="../../assets/newReport/export.png" alt="">
                下载报告
            </div>
        </div>
        <div class="bgc">
            <div class="sel">
                <div class="selR flex-align-center">
                    <div class="label1">年级：</div>
                    <el-select v-model="value5" style="margin: 0 20px;" placeholder="请选择" @change="Gradehange">
                        <el-option v-for="item in options1" :key="item.name" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="selR flex-align-center">
                    <div class="label1"> 班级：</div>
                    <el-select v-model="value11" style="margin: 0 20px;" placeholder="请选择" @change="classhange">
                        <el-option v-for="item in options2" :key="item.name" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="selR flex-align-center">
                    <div class="label1 labelX">姓名：</div>
                    <el-input v-model="input" placeholder="输入学生姓名搜索"></el-input>
                    <!--  -->
                    <el-button icon="el-icon-search" @click="getData" style="margin-left: 10px;">查询</el-button>
                    <el-button icon="el-icon-refresh" @click="refresh_condition" style="margin-left: 10px;">重置</el-button>
                </div>
            </div>
            <old_report_whole ref="old_report_whole" v-if="table_type == 1"></old_report_whole>
            <new_report_whole ref="new_report_whole" v-else></new_report_whole>

        </div>
    </div>
</template>

<script>
import old_report_whole from './old_report_whole';
import new_report_whole from './new_report_whole';
export default {
    //import引入组件才能使用
    components: {
        old_report_whole,
        new_report_whole
    },
    // props: ['orgId'],
    data() {
        return {
            schoolName: '',
            tableData: [],
            loadInfo: '暂无数据',
            userId: [],
            username: [],
            className: [],
            options1: [],
            options2: [],
            value5: [],
            value5_name: '',
            value11: [],
            input: '',
            excellent_add_total: [],
            excellent_minus_total: [],
            good_total: [],
            improve_add_total: [],
            once: true,
            whole_termList: [],
            whole_termValue: '',
            termKey: null,
            currentTerm: '',
            orgId: '',
            teac_Grade_class: '',
            roleType: '',
            schoolIdArr: ['181166', '1176', '181095', '181165', '1172', '181164', '1175', '181097', '1154'],
            table_type: '',
            yanshiId: '',
            term_type: '',
            // 报告类型
            reportList: [{
                value: 1,
                label: '前测报告'
            },
            {
                value: 2,
                label: '后测报告'
            },
            {
                value: 4,
                label: '月测报告'
            },
            {
                value: 5,
                label: '周测报告'
            }
            ],

            reportValue: 1, // 当前报告类型(前测/后测)
            reportType: '',
            //月测
            monthValue: '',
            monthList: [],
            monthId: '',
            monthName: '',
            account: ['15011591969', '18910941882', '13261203686', '13661102615'],
            downloadBtnType: false,
            reportStatus: 0,
            time: null,
            //周测
            weekValue: '',
            weekName: '',
            weekList: [],
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 获取orgId
        async getOrgId(val) {
            let data = {
                termKey: val
            }
            let resData = await this.$Api.Home.queryUserMaxOrg(data);
            let roles = [];
            this.yanshiId = resData.data.userId;
            resData.data.roles.map(item => {
                roles.push(item.id)
            })
            const hasTwo = roles.includes(2);
            const hasThree = roles.includes(3);
            if (!hasTwo && !hasThree) {
            } else if (hasTwo && hasThree) {
                this.roleType = 3;
                this.orgId = resData.data.userId == 459894 ? 6760 : resData.data.orgId;
                this.getGradeList();
            } else if (hasTwo) {
                this.roleType = 2;
                this.getTeacherClass(val)
            } else if (hasThree) {
                this.roleType = 3;
                this.orgId = resData.data.userId == 459894 ? 6760 : resData.data.orgId;
                this.getGradeList();
            }
        },
        //下拉
        // 查询年级列表
        async getGradeList() {
            let data = {
                orgId: this.orgId,
                termKey: this.termKey
            };
            let resData = await this.$Api.DataScreen.getGradeList(data);
            this.options1 = resData.data;
            if (this.options1.length != 0) {
                this.value5 = this.options1[0].id;
                this.value5_name = this.options1[0].name;
                this.getClassList();
            }

        },

        // 查询班级列表
        async getClassList() {
            let data = {
                orgId: this.orgId,
                gradeId: this.value5,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getClassList(data);
            this.options2 = resData.data;
            if (this.options2.length != 0) {
                this.value11 = resData.data[0].id;
                this.getData();
            }

        },
        //表单数据
        getData() {
            if (this.reportValue == 4 && this.monthList.length == 0) {
                this.table_type == 1 ? this.$refs.old_report_whole.tableData = [] : this.$refs.new_report_whole.tableData = [];
            } else {
                if (this.table_type == 1) {
                    this.$nextTick(() => {
                        this.$refs.old_report_whole.getParentData(this.orgId, this.termKey, this.roleType, this.value5, this.value5_name, this.value11, this.input, this.whole_termValue, this.currentTerm, this.table_type, this.reportValue, this.monthValue)
                    });

                } else {
                    if (this.reportValue == 4) {
                        this.$nextTick(() => {
                            this.$refs.new_report_whole.getParentData(this.orgId, this.termKey, this.roleType, this.value5, this.value5_name, this.value11, this.input, this.whole_termValue, this.currentTerm, this.table_type, this.reportValue, this.monthValue)
                        });
                    } else {
                        this.$nextTick(() => {
                            this.$refs.new_report_whole.getParentData(this.orgId, this.termKey, this.roleType, this.value5, this.value5_name, this.value11, this.input, this.whole_termValue, this.currentTerm, this.table_type, this.reportValue, this.weekValue)
                        });
                    }

                }
            }

        },
        async getIsNewDIm() {
            let data = {
                termKey: this.termKey,
                schoolId: this.$route.query.schoolId,
                examType: this.reportValue
            }
            let resData = await this.$Api.DataScreen.getIsNewDIm(data);
            this.table_type = resData.data;


            if (this.reportValue == 4) {
                this.getMonthTestList();
            } else if (this.reportValue == 5) {
                this.table_type = 2;
                this.weekParticipation();
            } else {
                // this.getIsNewDIm();
                this.getData();

            }
        },
        Gradehange(val) {
            const index = this.options1.findIndex(item => item.id == val);
            this.value5_name = this.options1[index].name;
            if (this.roleType == 2) {
                this.options2 = [];
                this.orgId = '';
                this.value11 = '';
                const targetObject = this.options1.find(obj => obj.id === val);
                this.orgId = this.yanshiId == 459894 ? 6760 : targetObject.schoolOrgId;
                let filteredArray = this.teac_Grade_class.filter(obj => obj.grade === val);
                filteredArray.forEach(item => {
                    this.options2.push({
                        id: item.classId,
                        name: item.className,
                    });
                })
                this.value11 = this.options2[0].id;
                this.getData();
            } else {
                this.getClassList();
            }

        },
        classhange() {
            this.getData();
        },
        refresh_condition() {
            this.value5 = '';
            this.value11 = '';
            this.input = '';
            this.whole_termValue = this.whole_termList[0].key;
            this.termKey = this.whole_termValue;
            this.currentTerm = this.whole_termList[0].termName;
            this.getData();
        },
        async getTermList() {
            let that = this;
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    this.whole_termList = res.data;
                    if (this.$route.query.fromType == 'multiRole') {
                        if (this.$route.query.testType) {
                            // let testTypedata = this.$route.query.testType == 4 ? '3' : this.$route.query.testType;
                            let testTypedata = this.$route.query.testType;
                            this.reportValue = Number(testTypedata);
                        }

                    }
                    if (this.$route.query.fromType == 'multiRole' && this.$route.query.type == 'school') {
                        if (this.$route.query.date) {
                            this.whole_termValue = Number(this.$route.query.date);
                            this.termKey = this.whole_termValue;
                            const index = this.whole_termList.findIndex(item => item.key == Number(this.$route.query.date));
                            this.currentTerm = this.whole_termList[index].termName;
                            this.getIsNewDIm();
                            this.getOrgInfoid();
                        }
                    } else if (this.$route.query.fromType == 'multiRole' && this.$route.query.type == 'class') {
                        this.whole_termValue = Number(this.$route.query.date);
                        this.termKey = this.whole_termValue;
                        const index = this.whole_termList.findIndex(item => item.key == Number(this.$route.query.date));
                        this.currentTerm = this.whole_termList[index].termName;
                        this.getIsNewDIm();
                        this.getOrgId(this.termKey);
                    } else {
                        this.whole_termValue = this.whole_termList[0].key;
                        this.termKey = this.whole_termValue;
                        this.currentTerm = this.whole_termList[0].termName;
                        this.term_type = this.whole_termList[0].term;
                        this.getIsNewDIm();
                        this.getOrgId(this.termKey);
                    }
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 修改学期
        termChange(val) {

            this.options1 = [];
            this.value5 = '';
            this.options2 = [];
            this.value11 = '';
            this.termKey = val;
            this.tableData = [];
            const index = this.whole_termList.findIndex(item => item.key == val);
            this.currentTerm = this.whole_termList[index].termName;
            this.term_type = this.whole_termList[index].term;
            this.monthList = [];
            this.monthValue = '';
            this.monthId = '';
            this.monthName = '';
            this.getIsNewDIm();
            if (this.reportValue == 4) {
                this.getMonthTestList();
            }
            if (this.reportValue == 5) {
                this.weekParticipation();
            }
            if (this.$route.query.fromType == 'multiRole' && this.$route.query.type == 'school') {
                if (this.$route.query.date) {
                    this.getOrgInfoid()
                }
            } else if (this.$route.query.fromType == 'multiRole' && this.$route.query.type == 'class') {
                this.getOrgId(val)
            } else {
                this.getOrgId(val)
            }


        },
        //查询教师关联班级
        async getTeacherClass(val) {
            let data = {
                termKey: val
            }
            let resData = await this.$Api.Report.getTeacherClass(data);
            this.teac_Grade_class = resData.data;
            let obj1 = {};
            //年级
            resData.data.forEach(item => {
                if (!obj1[item.grade]) {
                    this.options1.push({
                        id: item.grade,
                        orgId: item.orgId,
                        schoolOrgId: item.schoolOrgId,
                        name: this.teac_Grade(item.grade),
                    });
                    obj1[item.grade] = true;
                }
            })

            this.orgId = this.yanshiId == 459894 ? 6760 : this.options1[0].schoolOrgId;
            this.value5 = this.options1[0].id;
            this.value5_name = this.options1[0].name;
            // 班级
            let filteredArray = this.teac_Grade_class.filter(obj => obj.grade === this.value5);
            filteredArray.forEach(item => {
                this.options2.push({
                    id: item.classId,
                    name: item.className,
                });
            })
            this.value11 = this.options2[0].id;

            this.getData();

        },
        teac_Grade(id) {
            let Gradelist = {
                '1': '一年级',
                '2': '二年级',
                '3': '三年级',
                '4': '四年级',
                '5': '五年级',
                '6': '六年级',
            }
            return Gradelist[id]
        },
        hide_school() {
            let type = this.schoolIdArr.includes(this.$route.query.schoolId);
            if (this.schoolIdArr.includes(this.$route.query.schoolId) && this.termKey == '20232') {
                type = true;
            } else {
                type = false;
            }
            return type;
        },
        //大屏跳转请求orgid
        async getOrgInfoid() {
            let data = {
                bindId: this.$route.query.schoolId,
                level: 5,
                termKey: this.termKey
            };
            let resData = await this.$Api.DataScreen.getOrgInfo(data);
            this.orgId = this.yanshiId == 459894 ? 6760 : resData.data.id;
            this.getGradeList();
        },
        // 修改报告类型(前后测)
        reportChange(val) {
            this.getIsNewDIm();
            // this.monthList = [];
            // this.monthValue = '';
            // this.monthId = '';
            // this.monthName = '';
            // if (val == 4) {
            //     this.getIsNewDIm();
            //     this.getMonthTestList();

            // } else if (val == 5) {
            //     this.table_type = 2;
            //     this.weekParticipation();
            // } else {
            //     this.getIsNewDIm();

            //     this.getData();

            // }
        },
        // 月测-项
        monthChange(val) {
            this.monthValue = val;
            this.monthName = this.monthList.find(item => {
                return item.id == val;
            }).name;
            this.getData();
        },
        //月测列表详情
        getMonthTestList() {
            this.monthList = [];
            this.monthValue = '';
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.whole_termValue,
                examType: 4,
            }
            this.$Api.Report.getparticipationMonth(data)
                .then(res => {

                    if (res.data.length != 0) {
                        this.monthList = res.data;
                        this.monthValue = this.monthList[0].id;
                        this.monthName = this.monthList[0].name;
                        this.getData();
                    } else {
                        this.table_type == 1 ? this.$refs.old_report_whole.tableData = [] : this.$refs.new_report_whole.tableData = [];
                    }
                }).catch(err => {
                    // console.log('err', err)
                })
        },
        //批量下载 报告
        async getbatchdownload(type) {
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                // classId: this.value11,
                termIndex: this.whole_termValue,
                examType: this.reportValue,
                examId: this.reportValue == 4 ? this.monthValue : this.weekValue,
                version: 2,
                batch: true
            };
            let resData = await this.$Api.Report.getbatchdownload(data);
            if (resData.data != null) {
                this.reportStatus = 2;
                if (this.time) {
                    clearInterval(this.time); // 清除定时器
                }
                if (type == 'btn') window.open(resData.data)
            } else {
                if (type == 'btn') {
                    this.$message({
                        message: '报告生成中',
                        center: true,
                        offset: 70,
                    });
                }
            }
        },
        async getgenerate() {
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                // classId: this.value11,
                termIndex: this.whole_termValue,
                examType: this.reportValue,
                examId: this.reportValue == 4 ? this.monthValue : this.weekValue,
                version: 2,
                batch: true
            };
            let resData = await this.$Api.Report.getgenerate(data);
            if (resData.code == 200) {
                this.reportStatus = 1;
                that.time = setInterval(() => {
                    that.getbatchdownload('polling')
                }, 5000);
            }

        },
        // 获取周测类型
        async weekParticipation() {
            this.weekList = [];
            this.weekValue = '';
            let that = this;
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.whole_termValue,
                examType: 5,
            }
            let resData = await this.$Api.Report.getparticipationWeek(data);
            if (resData.data.length != 0) {
                this.weekList = resData.data;
                this.weekValue = this.weekList[0].id;
                this.weekName = this.weekList[0].name;
                this.getData();
            } else {
                this.$refs.new_report_whole.tableData = [];
            }
        },
        weekValTypeChange(val) {
            this.weekValue = val;
            this.weekName = this.monthList.find(item => {
                return item.id == val;
            }).name;
            this.getData();
        }

    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() { },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.getTermList();
        this.schoolName = this.$route.query.schoolName;
        this.downloadBtnType = this.account.includes(sessionStorage.getItem("name"));
        // if (this.$route.query.fromType == 'multiRole') {
        //     if (this.$route.query.testType) {
        //         let testTypedata = this.$route.query.testType == 4 ? '3' : this.$route.query.testType;
        //         // const index = this.reportList.findIndex(item => item.value == Number(testTypedata));
        //         this.reportValue = Number(testTypedata);
        //         // this.reportChange(this.reportValue)
        //         // this.currentReportType = this.reportList[index].label;
        //     }

        // }
    },
};


</script>
<style lang="less" scoped>
@import url("./less/report_whole.less");
</style>